import { Menu, MenuChild } from './menu';

const menuContribuinte = [
    {
        id: 7,
        name: 'Análise Fiscal',
        url: 'analise-fiscal',
        icon: 'airplay'
    },
    {
        id: 11,
        name: 'Cadastro',
        url: 'analise-fiscal/perfil/cadastro',
        icon: 'airplay'
    },
    {
        id: 12,
        name: 'Conta Corrente',
        url: 'analise-fiscal/conta-corrente',
        icon: 'airplay'
    },
    // {
    //     id: 8,
    //     name: 'Perfil do Contribuinte',
    //     url: '',
    //     icon: 'airplay',
    //     children: [
    //         {
    //             id: 11,
    //             name: 'Cadastro',
    //             url: 'analise-fiscal/perfil/cadastro',
    //             icon: 'airplay'
    //         },
    //         {
    //             id: 12,
    //             name: 'Conta Corrente',
    //             url: 'analise-fiscal/perfil/conta-corrente',
    //             icon: 'airplay'
    //         },
    //         {
    //             id: 14,
    //             name: 'Pedido Arquivo NF-e',
    //             url: 'analise-fiscal/perfil/pedido-nfe',
    //             icon: 'airplay'
    //         },
    //         {
    //             id: 15,
    //             name: 'Pedido Arquivo NFC-e',
    //             url: 'analise-fiscal/perfil/pedido-nfc',
    //             icon: 'airplay'
    //         },
    //         {
    //             id: 16,
    //             name: 'Arrecadação x Meta - Valores Atualizados IPCA',
    //             url: 'analise-fiscal/perfil/arrecadacao-meta',
    //             icon: 'airplay'
    //         },
    //         {
    //             id: 17,
    //             name: 'Arrecadação',
    //             url: 'analise-fiscal/perfil/arrecadacao',
    //             icon: 'airplay'
    //         },
    //         {
    //             id: 18,
    //             name: 'Histórico Econômico Fiscal',
    //             url: '',
    //             children: [
    //                 {
    //                     id: 181,
    //                     name: 'Histórico das Saídas',
    //                     url: 'analise-fiscal/perfil/historico/saidas'
    //                 },
    //                 {
    //                     id: 182,
    //                     name: 'Histórico das Ações Fiscais Concluídas',
    //                     url: 'analise-fiscal/perfil/historico/acoes-fiscais-concluidas'
    //                 },
    //                 {
    //                     id: 183,
    //                     name: 'Histórico Monitoramento Fiscal Virtual',
    //                     url:
    //                         'analise-fiscal/perfil/historico/monitoramento-fiscal-virtual'
    //                 },
    //                 {
    //                     id: 184,
    //                     name: 'Histórico de Monitoramento Fiscal',
    //                     url:
    //                         'analise-fiscal/perfil/historico/historico-monitoramento-fiscal'
    //                 }
    //             ],
    //             icon: 'airplay'
    //         }
    //     ]
    // },
    {
        id: 9,
        name: 'Indicadores/Cruzamentos do Contribuinte',
        url: '',
        icon: 'airplay',
        children: [
            {
                id: 196,
                name: 'FDI',
                url: 'analise-fiscal/indicadores-cruzamento/fdi'
            },
            {
                id: 191,
                name: 'Índices Econômicos Fiscais',
                url: 'analise-fiscal/indicadores-cruzamento/indices-economicos'
            },
            {
                id: 192,
                name: 'EFD',
                url: '',
                children: [
                    {
                        id: 200,
                        name:
                            'Crédito Indevido de Contribuinte diferente de Normal/ME/EPP',
                        url:
                            'analise-fiscal/indicadores-cruzamento/efd/credito-indevido-diferente'
                    },
                    {
                        id: 201,
                        name: 'Crédito Indevido de Contribuinte SN',
                        url:
                            'analise-fiscal/indicadores-cruzamento/efd/credito-indevido-sn'
                    },
                    {
                        id: 202,
                        name: 'EFD Saldo Credor Continuado',
                        url:
                            'analise-fiscal/indicadores-cruzamento/efd/saldo-credor-continuado'
                    },
                    {
                        id: 203,
                        name:
                            'EFD Omissa/Sem Movimento X Receita ICMS 1015 X Nfe Emitidas',
                        url:
                            'analise-fiscal/indicadores-cruzamento/efd/omissa-sem-movimento'
                    }
                ]
            },
            {
                id: 193,
                name: 'NF-e',
                url: '',
                children: [
                    {
                        id: 300,
                        name: 'Entradas NF-e',
                        url: 'analise-fiscal/indicadores-cruzamento/nfe/entradas'
                    },
                    {
                        id: 301,
                        name: 'Entradas NF-e e Outros',
                        url:
                            'analise-fiscal/indicadores-cruzamento/nfe/entradas-outros'
                    },
                    {
                        id: 302,
                        name: 'Saídas NF-e',
                        url: 'analise-fiscal/indicadores-cruzamento/nfe/saidas'
                    },
                    {
                        id: 303,
                        name: 'Saídas NF-e e Outros',
                        url: 'analise-fiscal/indicadores-cruzamento/nfe/saidas-outros'
                    },
                    {
                        id: 304,
                        name: 'Relação NF-e Recebidas',
                        url:
                            'analise-fiscal/indicadores-cruzamento/nfe/relacao-recebidas'
                    },
                    {
                        id: 305,
                        name: 'Relação NF-e Emitidas',
                        url:
                            'analise-fiscal/indicadores-cruzamento/nfe/relacao-emitidas'
                    },
                    {
                        id: 306,
                        name: 'NF-e por Municipio',
                        url: 'analise-fiscal/indicadores-cruzamento/nfe/municipio'
                    },
                    {
                        id: 307,
                        name: 'NF-e Geral',
                        url: 'analise-fiscal/indicadores-cruzamento/nfe/geral'
                    },
                    {
                        id: 308,
                        name: 'NF-e Emitidas para Sócios',
                        url:
                            'analise-fiscal/indicadores-cruzamento/nfe/emitidas-socios'
                    }
                ]
            },
            {
                id: 194,
                name: 'CT-e',
                url: 'analise-fiscal/indicadores-cruzamento/ct-e'
            },
            {
                id: 195,
                name: 'COMEX',
                url: '',
                children: [
                    {
                        id: 400,
                        name:
                            'NF-e/EFD (Monitorado x Destinatário do Tocantins)',
                        url:
                            'analise-fiscal/indicadores-cruzamento/comex/monitorado-destinatario-ce'
                    },
                    {
                        id: 401,
                        name:
                            'NF-e/EFD (Monitorado) x EFD/OIE (Destinatário de outra UF)',
                        url:
                            'analise-fiscal/indicadores-cruzamento/comex/monitorado-destinatario-uf'
                    },
                    {
                        id: 402,
                        name: 'NF-e x SITRAM',
                        url: 'analise-fiscal/indicadores-cruzamento/comex/nfe-sitram'
                    },
                    {
                        id: 403,
                        name: 'NF-e (Ausência de Credenciamento)',
                        url:
                            'analise-fiscal/indicadores-cruzamento/comex/nfe-ausencia-credenciamento'
                    },
                    {
                        id: 404,
                        name: 'Situação NF-e (Siscoex)',
                        url:
                            'analise-fiscal/indicadores-cruzamento/comex/situacao-nfe-siscoex'
                    },
                    {
                        id: 405,
                        name: 'Decadência',
                        url: 'analise-fiscal/indicadores-cruzamento/comex/decadencia'
                    },
                    {
                        id: 406,
                        name: 'Exportação não comprovada na EFD',
                        url:
                            'analise-fiscal/indicadores-cruzamento/comex/exportacao-nao-comprovada-efd'
                    },
                    {
                        id: 407,
                        name:
                            'Qtd. Recebida x Exportada (Remetente outra UF)',
                        url:
                            'analise-fiscal/indicadores-cruzamento/comex/qtd-recebida-exportada'
                    },
                    {
                        id: 408,
                        name: 'Entradas x Saídas (Empresas do Tocantins)',
                        url:
                            'analise-fiscal/indicadores-cruzamento/comex/entradas-saidas'
                    },
                    {
                        id: 409,
                        name: 'Entrada x Saída (Remetente de outra UF)',
                        url:
                            'analise-fiscal/indicadores-cruzamento/comex/entrada-saida-outra-uf'
                    },
                    {
                        id: 410,
                        name:
                            'Quantidade Recebida x Exportada (Remetente Tocantins)',
                        url:
                            'analise-fiscal/indicadores-cruzamento/comex/quantidade-recebida-exportada-remetente-ce'
                    }
                ]
            },
            {
                id: 196,
                name: 'Simples Nacional',
                url: '',
                children: [
                    {
                        id: 1951,
                        name: 'PGDAS-D X Sistemas SEFAZ',
                        url:
                            'analise-fiscal/indicadores-cruzamento/simples-nacional/pgdas-sefaz'
                    },
                    {
                        id: 1952,
                        name: 'ICMS Declarado X ICMS Recolhido',
                        url:
                            'analise-fiscal/indicadores-cruzamento/simples-nacional/icms-declarado-recolhido'
                    },
                    {
                        id: 1953,
                        name: 'Empresa Regime Caixa',
                        url:
                            'analise-fiscal/indicadores-cruzamento/simples-nacional/empresa-regime-caixa'
                    },
                    {
                        id: 1954,
                        name:
                            'Exclusão/Impedimento do Simples Nacional Nível Estadual',
                        url:
                            'analise-fiscal/indicadores-cruzamento/simples-nacional/exclusao-estadual'
                    }
                ]
            }
        ]
    },
    {
        id: 10,
        name: 'Acompanhamento',
        url: 'analise-fiscal/acompanhamento',
        icon: 'airplay'
    },
    {
        id: 11,
        name: 'Observação',
        url: 'analise-fiscal/observacao',
        icon: 'airplay' 
    },
    {
        id: 13,
        name: 'Documentos',
        url: 'analise-fiscal/documentos'
    },
    {
        id: 19,
        name: 'RedeSim',
        url: 'analise-fiscal/redesim'
    }
]

export const MENUS: Menu[] = [
    {
        id: 20000,
        name: 'Painel do Contribuinte',
        level: 1,
        icon: 'airplay',
        url: 'painel-contribuinte',
        children: menuContribuinte
    } as Menu,
    {
        id: 30007,
        name: 'Comunicação Eletrônica',
        level: 1,
        icon: 'clipboard',
        url: 'comunicado-eletronico',
        children: [
            {
                id: 91,
                name: 'Comunicação Eletrônica Recebida',
                url: '/recebido',
                icon: 'airplay'
            },
            {
                id: 92,
                name: 'Comunicação Eletrônica Enviada',
                url: '/enviado',
                icon: 'airplay'
            },
            {
                id: 93,
                name: 'Comunicações Arquivadas',
                url: '/arquivado',
                icon: 'airplay'
            }
        ]
    } as Menu,
    // {
    //     id: 9,
    //     level: 1,
    //     name: 'Comunicado Institucional',
    //     icon: 'clipboard',
    //     url: 'comunicado-institucional',
    //     children: [
    //         {
    //             id: 91,
    //             name: 'Comunicado Institucional Enviado',
    //             url: '/enviados',
    //             icon: 'airplay'
    //         },
    //         {
    //             id: 92,
    //             name: 'Comunicado Institucional Arquivado',
    //             url: '/arquivados',
    //             icon: 'airplay'
    //         }
    //     ]
    // } as Menu,

    {
        id: 30008,
        level: 1,
        name: 'Monitoramento Fiscal',
        icon: 'clipboard',
        url: 'monitoramento-fiscal',
        children: [
            {
                id: 77,
                name: 'Planejamento',
                url: 'planejamento',
                icon: 'airplay'
            },
            {
                id: 78,
                name: 'Pam',
                icon: 'airplay',
                children: [
                    {
                        id: 989,
                        name: 'Gerar Pam',
                        url: 'pam/gerar-pam'
                    },
                    {
                        id: 990,
                        name: 'Alterar Distribuição e Designar',
                        url: 'pam/alterar-distribuicao-designar'
                    },
                    {
                        id: 991,
                        name: 'Distribuição por Agente',
                        url: 'pam/distribuicao-agente'
                    },
                    {
                        id: 992,
                        name: 'Análise de distribuição de PAM para Supervisor',
                        url: 'pam/analise-distribuicao-supervisor'
                    }
                ]
            },
            {
                id: 79,
                name: 'Relatórios',
                url: 'relatorios',
                icon: 'airplay'
            }
        ]
    } as Menu,
    {
        id: 30009,
        level: 1,
        name: 'Monitoramento Fiscal Virtual',
        icon: 'clipboard',
        url: 'monitoramento-fiscal-virtual',

        children: [
            {
                id: 3.1,
                name: 'Resultado',
                url: '/resultado'
            },
            {
                id: 3.2,
                name: 'Parâmetros',
                url: '/parametros'
            },
            {
                id: 3.2,
                name: 'Novo Parâmetro',
                url: '/novo-parametro'
            }
        ]
    } as Menu,
    {
        id: 30010,
        name: 'Cadastro',
        level: 1,
        icon: 'user',
        url: 'cadastro-menu',
        children: menuContribuinte
    } as Menu,
    {
        id: 30011,
        name: 'Produtor Rural',
        level: 1,
        icon: 'user',
        url: 'produtor-rural-menu',
        children: []
    } as Menu,
    {
        id: 30012,
        level: 1,
        name: 'Agente Fiscal',
        icon: 'user',
        url: 'agente-fiscal',
        children: []
    } as Menu,
    {
        id: 30000,
        level: 1,
        name: 'Análise de Dados',
        icon: 'user',
        url: 'monitor-de-desempenho',
        children: [
            {
                id: 34510,
                name: 'Total NF-e',
                url: 'total-nf-e'
            },
            {
                id: 3451,
                name: 'Arrecadação',
                url: 'arrecadacao'
            },
            {
                id: 3452,
                name: 'Contribuintes',
                url: 'contribuintes',
                children: [
                    {
                        id: 989,
                        name: 'Empresas por Sócios',
                        url: 'contribuintes/empresas-por-socios'
                    },
                    {
                        id: 990,
                        name: 'Empresas por Categoria',
                        url: 'contribuintes/empresas-por-categoria'
                    },
                    {
                        id: 991,
                        name: 'Contribuintes por CADINE',
                        url: 'contribuintes/cadine'
                    },
                    {
                        id: 992,
                        name: 'Contribuintes por CNAE',
                        url: 'contribuintes/cnae'
                    },
                    {
                        id: 993,
                        name: 'Contribuintes por Distrito',
                        url: 'contribuintes/distrito'
                    },
                    {
                        id: 994,
                        name: 'Contribuintes por Início de Atividade',
                        url: 'contribuintes/inicio-de-atividade'
                    },
                    {
                        id: 995,
                        name: 'Contribuintes por Natureza Jurídica',
                        url: 'contribuintes/natureza-juridica'
                    },
                    {
                        id: 996,
                        name: 'Contribuintes por Procurador',
                        url: 'contribuintes/procurador'
                    },
                    {
                        id: 997,
                        name: 'Contribuintes por Regime de Apuração',
                        url: 'contribuintes/regime-de-apuracao'
                    },
                    {
                        id: 998,
                        name: 'Contribuintes por Regime de Recolhimento',
                        url: 'contribuintes/regime-de-recolhimento'
                    },
                    {
                        id: 999,
                        name: 'Contribuintes por Regime Tributário',
                        url: 'contribuintes/regime-tributario'
                    },
                    {
                        id: 9910,
                        name: 'Empresas por Setor Econômico',
                        url: 'contribuintes/setor-economico'
                    },
                    {
                        id: 9911,
                        name: 'Contribuintes por Situação',
                        url: 'contribuintes/situacao'
                    },
                    {
                        id: 9912,
                        name: 'Contribuintes por Tipo de Benefício',
                        url: 'contribuintes/tipo-de-beneficio'
                    },
                    {
                        id: 9913,
                        name: 'Contribuintes por Tipo de Fiscalização',
                        url: 'contribuintes/tipo-de-fiscalizacao'
                    },
                    {
                        id: 9914,
                        name: 'Contribuintes por Tipo de Procurador',
                        url: 'contribuintes/tipo-de-procurador'
                    },
                    {
                        id: 9915,
                        name: 'Gestores por Departamento',
                        url: 'contribuintes/gestores-por-departamento'
                    },
                    {
                        id: 9916,
                        name: 'Empresas Consolidado',
                        url: 'contribuintes/empresas-consolidado'
                    },
                    {
                        id: 9917,
                        name: 'Empresas por Quantidade de Acessos',
                        url: 'contribuintes/quantidade-de-acessos'
                    },
                    {
                        id: 998,
                        name: 'Regime Especial de Fiscalização',
                        url: 'contribuintes/regime-especial-de-fiscalizacao'
                    }
                ]
            },
            {
                id: 3453,
                name: 'Monitoramento',
                url: 'monitoramento'
            },
            {
                id: 3454,
                name: 'Apuração',
                url: 'apuracao'
            },
            {
                id: 3455,
                name: 'NF-e',
                url: 'nf-e'
            },
            {
                id: 3457,
                name: 'Operações de Entradas e Saídas Agrupadas',
                url: 'entradas-e-saidas',
                children: [
                    {
                        id: 34571,
                        name: 'CFOP',
                        url: 'entradas-e-saidas/cfop'
                    },
                    {
                        id: 34572,
                        name: 'CNAE',
                        url: 'entradas-e-saidas/cnae'
                    },
                    {
                        id: 34573,
                        name: 'Segmento Econômico',
                        url: 'entradas-e-saidas/segmento-economico'
                    },
                    {
                        id: 34574,
                        name: 'Destinatário',
                        url: 'entradas-e-saidas/destinatario'
                    }
                ]
            },
            {
                id: 3458,
                name: 'Painel do Atendente',
                url: 'painel-do-atendente',
                children: [
                    {
                        id: 34581,
                        name: 'Atendentes em Atendimento',
                        url: 'painel-do-atendente/atendentes-em-atendimento'
                    },
                    {
                        id: 34582,
                        name: 'Quantidades de Chamadas em Aguardo',
                        url: '/chamadas-em-aguardo'
                    },
                    {
                        id: 34583,
                        name: 'Quantidade de Protocolos Atendidos',
                        url: 'painel-do-atendente/protocolos-atendidos'
                    },
                    {
                        id: 34583,
                        name: 'Acessos dos Atendentes',
                        url: 'painel-do-atendente/acessos-dos-atendentes'
                    },
                    {
                        id: 34584,
                        name: 'Relatório de Atendimento',
                        url: 'painel-do-atendente/relatorio-de-atendimento'
                    },
                    {
                        id: 34585,
                        name: 'Histórico de Atendimento',
                        url: 'painel-do-atendente/historico-de-atendimento'
                    }
                ]
            },
            {
                id: 3459,
                name: 'SPED Fiscal',
                url: 'nf-e'
            },
            {
                id: 3460,
                name: 'Eventos de Usuário',
                url: 'nf-e'
            }
        ]
    } as Menu,
    {
        id: 30013,
        name: 'Documentos Fiscais',
        level: 1,
        icon: 'file',
        url: 'doc-fiscais',
        children: []
    } as Menu,
    {
        id: 30014,
        name: 'GRM',
        level: 1,
        icon: 'briefcase',
        url: 'grm',
        children: [
            {
                id: 5551,
                name: 'Monitoramento Fiscal',
                url: 'monitoramento-fiscal'
            },
            {
                id: 5552,
                name: 'Arrecadação Mensal',
                url: 'arrecadacao-mensal'
            },
            {
                id: 5553,
                name: 'Arrecadação Acumulada',
                url: 'arrecadacao-acumulada'
            },
            {
                id: 5554,
                name: 'Débitos Fiscais Recuperados',
                url: 'debitos-fiscais-recuperados'
            },
            {
                id: 5555,
                name: 'Débitos Fiscais a Vencer',
                url: 'debitos-fiscais-a-vencer'
            },
            {
                id: 5556,
                name: 'Débitos Fiscais Vencidos',
                url: 'debitos-fiscais-vencidos'
            },
            {
                id: 5557,
                name: 'Omissão de Declarações',
                url: 'omissao-de-declaracoes'
            },
            {
                id: 5558,
                name: 'Cumprimento da Obrigatoriedade de Emissão de NF-e',
                url: 'obrigatoriedade-de-emissao-de-nfe'
            },
            {
                id: 5559,
                name: 'Medidas de Gestão Implementadas',
                url: 'medidas-de-gestao-implementadas'
            },
            {
                id: 55510,
                name: 'Relatórios',
                url: 'relatorios'
            }
        ]
    } as Menu,

    {
        id: 30015,
        name: 'Solicitação de Medidas de Gestão',
        level: 1,
        icon: 'briefcase',
        url: 'solicitacao-de-medidas-de-gestao',
        children: []
    } as Menu,
    {
        id: 30016,
        name: 'Orientador Supervisor',
        level: 1,
        icon: 'briefcase',
        url: 'orientador-supervisor',
        children: [
            {
                id: 31,
                name: 'Visualizar',
                url: 'visualizar',
                icon: 'briefcase',

                children: [
                    {
                        id: 4,
                        name: 'Confirmacao',
                        url: 'visualizar/confirmacao',
                        icon: 'briefcase'
                    }
                ]
            }
        ]
    } as Menu,
    // {
    //     id: 4,
    //     name: 'CATRI/CEPAC',
    //     level: 1,
    //     icon: 'briefcase',
    //     url: 'catri-cepac',
    //     children: []
    // } as Menu,
    // {
    //     id: 422,
    //     name: 'COREX Coordenador',
    //     level: 1,
    //     icon: 'briefcase',
    //     url: 'corex-coordenador',
    //     children: []
    // } as Menu,
    // {
    //     id: 445,
    //     name: 'CATRI Coordenador',
    //     level: 1,
    //     icon: 'briefcase',
    //     url: 'catri-coordenador',
    //     children: []
    // } as Menu,
    {
        id: 30017,
        name: 'Mandado e Termos',
        level: 1,
        icon: 'briefcase',
        url: 'mandado-e-termos',
        children: []
    } as Menu,
    {
        id: 30018,
        name: 'Histórico de Justificativas',
        level: 1,
        icon: 'briefcase',
        url: 'historico-de-justificativas',
        children: []
    } as Menu,
    {
        id: 30019,
        name: 'Configuração',
        level: 1,
        icon: 'settings',
        url: 'configuracao',
        children: []
    } as Menu,
    {
        id: 100,
        name: 'Sair',
        icon: 'log-out',
        url: 'sair'
    } as Menu
];
