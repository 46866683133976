import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuOpcoesService } from 'src/app/shared/menu-opcoes/menu-opcoes.service';
import { MenuService } from '../../shared/menu/menu.service';
import { MENUS } from '../../shared/menu/menu.mock';

@Component({
    selector: 'app-menu-opcoes',
    templateUrl: './menu-opcoes.component.html',
    styleUrls: ['./menu-opcoes.component.scss']
})
export class MenuOpcoesComponent implements OnInit {
    @Input() idParam: any[];
    @Input() open: boolean;

    backBtn = false;
    currentRoute: string;
    options = [];

    constructor(
        private menuService: MenuService,
        private menuOpcoesService: MenuOpcoesService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.menuOpcoesService.menuStatus.subscribe(data => {
            this.open = data;
        });
        this.menuService.activeMenu.subscribe(data => {
            this.options = data.children;
        });
        this.menuService.breadcrumbMenuStatus.subscribe(data => {
            this.backBtn = (data.length == 1 || data.length == 0) ? false : true;
        });

        this.options = MENUS;

        this.currentRoute = this.router.url.split('/')[1];
        
        if(this.route.params) {
            this.router.url.split('/').forEach((url, index) => {
                this.route.params.forEach(paramObj => {
                    const param = paramObj[Object.keys(paramObj)[0]];
                    if(param == url) {
                        if(index > 2) {
                            this.currentRoute = this.currentRoute + "/" + this.router.url.split('/')[index-1];
                        }
                    }
                });
            });
        }

        let currentUrl = this.router.url;
        this.route.params.forEach(param => {
            currentUrl = currentUrl.replace(
                `/${param[Object.keys(param)[0]]}`,
                ''
            );
        });

        currentUrl.split('/').forEach(url => {
            this.options.forEach(option => {
                if (
                    option.children &&
                    (option.url === url ||
                        option.name.split(' ')[0].toUpperCase() ===
                            url.toUpperCase())
                ) {
                    this.options = option.children;
                }
            });
        });
    }

    back(): void {
        this.menuService.backMenu();
    }

    setMenu(menu: any) {
        if (menu.children) {
            this.backBtn = true;
            this.menuService.setMenu(menu);
        } else {
            this.backBtn = false;
            this.menuService.resetBreadcrumb();
        }
    }
}
