import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BreadcrumbItem } from './breadcrumb-item';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
    itens: BreadcrumbItem[] = [];
    itensParent: BreadcrumbItem[];
    rotasSemTela = [
        { nome: 'Indicadores/Cruzamentos do Contribuinte', path: '/analise-fiscal' },
        { nome: 'Comex', path: '/analise-fiscal' },
        { nome: 'Simples Nacional', path: '/analise-fiscal' },
        { nome: 'NF-e', path: '/analise-fiscal' },
        { nome: 'EFD', path: '/analise-fiscal' }
    ]

    constructor(
        private router: ActivatedRoute,
        private route: Router
    ) {}

    ngOnInit() {
        this.geraBreadcrumb();
    }

    geraBreadcrumb(): void {
        const home = new BreadcrumbItem('/', '', 'home');
        const itens: BreadcrumbItem[] = [home];
        const titleContribuinte = sessionStorage.getItem("contribuinteSelecionado");
        const parents = this.getParents(this.router);

        for (let i = parents.length - 1; i >= 0; i--) {
            const parent = parents[i];
          
            if (parent?.snapshot?.routeConfig) {
              const { path, data } = parent.snapshot.routeConfig;
              const title = data?.title || '';
              const rotaSemTela = this.rotasSemTela.find(item => item.nome === title);

              if (rotaSemTela) {
                itens.push(new BreadcrumbItem(rotaSemTela.path, title));
              } else if (path.includes('analise-fiscal') && titleContribuinte) {
                itens.push(new BreadcrumbItem(`/${path}`, titleContribuinte));
                itens.push(new BreadcrumbItem(`/${path}`, title));
              } else {
                itens.push(new BreadcrumbItem(`/${path}`, title));
              }
            }
        }

        if(this.hasData()) {
            itens.push(new BreadcrumbItem(
                `/${this.router.routeConfig.path}`,
                this.router.routeConfig.data.title
            ));
        }
         
        itens.forEach(item => {
            const urlItem = item.url.replace(/\/:[^/]+/g, '');
            const url = this.router.snapshot['_routerState'].url;
            const indexPath = url.indexOf(urlItem);
            item.url = url.substring(0, indexPath + urlItem.length);
        });

        this.itens = itens;
    }

    getParents(route: ActivatedRoute): ActivatedRoute[] {
        const parents = [];
        let currentRoute = route.parent;
        while (currentRoute) {
          parents.push(currentRoute);
          currentRoute = currentRoute.parent;
        }
        return parents;
    }

    private hasParent(): boolean {
        return (
            this.router.parent &&
            this.router.parent.routeConfig &&
            this.router.parent.routeConfig.path &&
            this.router.parent.routeConfig.data &&
            this.router.parent.routeConfig.data.title
        );
    }

    private hasData(): boolean {
        return (
            this.router.routeConfig &&
            this.router.routeConfig.path &&
            this.router.routeConfig.data &&
            this.router.routeConfig.data.title
        );
    }
}
