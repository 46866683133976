import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable()
export class GedService {

  private apiUrl = environment.API_URL;

  constructor(private http: HttpClient) {

  }

  adicionarDocumentoGed(file, obj) {
    const formData = new FormData();
    formData.append('file', file);

    const json = JSON.stringify(obj);
    const blob = new Blob([json], { type: 'application/json' });
    formData.append('dto', blob);

    return this.http.post(
      `${this.apiUrl}/contribuinte/ged/cadastrarDocumento`, formData
    )
  }
}